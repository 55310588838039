<template>
    <div>
      <b-modal  v-model="mode" centered 
           no-close-on-esc
           no-close-on-backdrop 
           hide-header-close 
           hide-footer class="" >
          
          <form  @submit.prevent="submitHandler">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="inclusion"
                    >Edit Plan Inclusion Title</label
                  >
                  <input
                    type="text"
                    v-model="state.title"
                    class="form-control"
                    @blur="v$.title.$touch()"
                    :class="v$.title.$errors.length > 0 ? 'is-invalid' : ''"
                    placeholder="Enter Inclusion Title"
                    id="inclusion"
                  />
                  <div class="invalid-feedback"  v-for="error of v$.title.$errors" :key="error.$uid">{{`Title ${error.$message}`}}</div>  
                </div>
              </div>
                
           
                <div class="col-12 text-end">
                  <button
                    type="button"
                    class="btn btn-danger me-1"
                    @click="closeMode"
                  >
                    <i class="bx bx-x me-1"></i> Cancel
                  </button>
                 
                  <button-spinner 
                      className='btn btn-success' 
                      typeName="submit" 
                      :loading="spinner ? true : false" 
                      :disableValue="spinner ? true : false" 
                      buttonName="Submit"/>
                </div>
              </div>
          </form>
      </b-modal>

  
  </div>
</template>

<script>
import {computed,reactive} from 'vue';
import useVuelidate from '@vuelidate/core'
import { required,helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
import buttonSpinner from '../../../../components/utility/button-spinner'

export default {
  props:{
    modalShow:Boolean,
    field:Object
    
  },
  components:{
    buttonSpinner
  },  
  setup(props,{ emit }) {
    const store = useStore();
    const mode = computed(()=>{
        return  props.modalShow
    })

    const content = computed(()=>{
        return  props.field
    })

    const closeMode = () =>{
        emit('modalClose')
    }
    const state = reactive(content.value)
   
    const rules = {title: { required: helpers.withMessage('field cannot be empty', required)}}
    const v$ = useVuelidate(rules, state)

    async function submitHandler(){
      const result =await v$.value.$validate()
      if (!result) {
        return
      }else{
        await store.dispatch('subscription/editInclusion', state)
        const error = store.getters['subscription/error']
        if(error === false) {
            emit('modalClose')
        }
        store.dispatch('subscription/getInclusion',{page:1,search:''});

      }
    }
    const  spinner =  computed(() =>{ return  store.getters['subscription/processingData']})
    return {
      spinner,
      mode,
      closeMode,
      state, 
      v$,
      submitHandler
    }
  }
}
</script>

<style>

</style>